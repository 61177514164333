import React from "react";
import "../../styles/gst.css";
import Testimonial from "../../components/Testimonial";
import Layout from "../../components/Layout";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
import FAQAccord from "../../components/GFlush";
import Guidance from "../../components/GSTTemp/Guidance";
// import Hero from '../../components/Hero';
import ImgContent from "../../components/GSTTemp/ImgContent";
import ContentForm from "../../components/GSTTemp/ContentForm";
import Cta from "../../components/Cta";
import ContentImg from "../../components/GSTTemp/ContentImg";
import SliderContent from "../../components/GSTTemp/SliderContent";
import AboutWithForm from "../../components/GSTTemp/AboutWithForm";
import Package from "../../components/GSTTemp/Package";

export default function GstLutFilling() {
  /* Slider */
  // const lutSlider = [
  //   {
  //     header_color: false,
  //     content_color: false,
  //     background: `linear-gradient(139.4deg, rgb(198, 243, 242) 28.54%, rgb(181, 201, 253) 104.43%)`,
  //     header: `GST Letter Of Undertaking (LUT) Return Filing`,
  //     content: `India's Best GST LUT Service Providers`,
  //     image: '/imgs/registration/msme/msme-doc.png',
  //     alt_tag: 'Best Online GST (LUT) return filing Service Near Bangalore',
  //   },
  //   {
  //     header_color: false,
  //     content_color: false,
  //     background: `linear-gradient(139.4deg, rgb(252, 237, 216) 28.54%, rgb(245, 197, 198) 104.43%)`,
  //     header: `Online GST LUT Return Filing`,
  //     content: `Best LUT Filing Service in Hosur`,
  //     image: '/imgs/registration/msme/msme-slide.png',
  //     alt_tag: 'Best Online GST (LUT) return filing Service in Hosur',
  //   },
  //   {
  //     header_color: false,
  //     content_color: false,
  //     background: `linear-gradient(139.4deg, rgb(207, 249, 216) 28.54%, rgb(243, 238, 160) 104.43%)`,
  //     header: `LUT Return Filing Service`,
  //     content: `Best GST Registration Service Platform`,
  //     image: '/imgs/registration/msme/ms-me-reg.png',
  //     alt_tag: 'Best Online GST (LUT) return filing Service in Hosur',
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `GST LUT Filing`,
    buyBtnLink: `#pricing-buy`,
    price: `999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: "/imgs/icons/gst.png",
        alt_tag: "PAN & TAN Registration",
        background: "",
        plus: "+",
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: "/imgs/icons/itr_filing.png",
        alt_tag: "TODAYFILINGS ITR E-filing service",
        background: "bg-f78acb",
        plus: "+",
        plus_dnone: "d-none d-md-block",
      },
      {
        points: `MSME Registration`,
        icon: "/imgs/icons/enterprises.png",
        alt_tag: "MSME Registration",
        background: "bg-eb6b3d",
        plus: "+",
      },
      {
        points: `Tax Consultancy Service `,
        icon: "/imgs/icons/consultant.png",
        alt_tag: "Tax Consultancy Service",
        background: "bg-c679e3",
      },
    ],
  };

  /* About Data With Form*/
  const lutAboutData = {
    header: `Letter Of Undertaking Return Filling`,
    sub_title: `For Personal and Business Related Letter of Undertaking Return Filing with TODAYFILINGS Experts.`,
    content_paragraph: [
      // `Any registered taxpayer exporting products or services may use LUTs. Anyone who has been charged with
      // tax evasion under the legislation for an amount of Rs. 2.5 crore or more is ineligible to offer LUTs.`,

      // `Bonds should be issued to all other assesses in the event that the export is made without payment of the
      // IGST (together with those who have been prosecuted for tax evasion of Rs. 2.5 crores or more under the GST
      //    legislation). Online submission of letters of the undertaking is possible through the GST portal.`,

      `The user certifies in the letter of undertaking that all GST obligations have been satisfied. 
       Additionally, Central Tax Notification
       No. 37 (2017) You must offer LUT in order to export goods, services, or both without paying IGST.
       Any registered taxpayer exporting products or services may use LUTs. Anyone who has been charged with 
      tax evasion under the legislation for an amount of Rs. 2.5 crore or more is ineligible to offer LUTs.`,
    ],
  };

  /* Img Content Component Data */
  const lutIcData = {
    id: '',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header:
      'When Should A LUT Be Submitted, And What Was The Position Prior To GST?',
    image: '/imgs/registration/ngo/fcra-registration.png',
    alt_tag: 'Online LUT return filling',
    paragraph: ``,
    points: [
      {
        content: `Letter of undertaking needs to be filed/submitted online before exporting products or 
        services. Previously, exporters were required to manually submit two completed and signed RFD-11 forms on 
        business letterhead.`,
        icon: true,
      },
      {
        content: ` One was sent to the Jurisdictional Deputy/Assistant Commissioner with authority over their 
        primary place of business, where the export paperwork were confirmed via the ICEGATE system.`,
        icon: true,
      },
      {
        content: `The other to the agency in charge of clearing customs.`,
        icon: true,
      },
      {
        content: `Similar to the previous excise scheme, this cost exporters a lot of time and money to comply 
        with. Finally, this process has been streamlined and rationalized to give all parties involved in an 
        exporter's entire export process transparency. Keep in mind that the bond must be manually submitted 
        and provided on paper without a judicial stamp.`,
        icon: false,
      },
    ],
  };

  /* Content Form Component Data */
  const lutCfData = {
    id: '',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: 'How To Fill Out A LUT On The GST Portal',
    content: ``,
    body: [
      {
        head: 'To provide LUT on the GST Portal/GSTN, complete these steps:',
      },
      {
        head: 'Step-1',
        points: `Log in to the GST Portal.`,
        icon: true,
      },
      {
        head: 'Step-2',
        points: `From the 'SERVICES' tab 'User Services', choose 'Furnish Letter of Undertaking(LUT)'.`,
        icon: true,
      },
      {
        head: 'Step-3',
        points: `Select the financial year for which the Letter of Undertaking is applied from the 
        drop-down list next to "LUT Applied for Financial Year." E.g., 2021-22. Note: Please upload the Letter 
        of Undertaking using the 'Choose File' option on the same window if you have already manually submitted 
        one for any of the prior months.`,
        icon: true,
      },
      {
        points: 'Only PDF and JPEG files are accepted.',
        icon: true,
      },
      {
        points: 'The upload file size limit is 2 MB.',
        icon: true,
      },
      {
        head: 'Step-4',
        points: `Complete the necessary information on the Letter of Undertaking Form/ GST RFD-11 that 
        appears on the screen. The following fields on the form must be filled out:`,
        icon: true,
      },
      {
        points: `(a): Click on each of the three boxes to mark it. By doing this, the exporter agrees to the 
        following: The export of goods or services must be finished within three months of the date the export 
        invoice was issued, or within any other time frame the Commissioner may specify.`,
        icon: true,
      },
      {
        points: 'applying the GST regulations to exports.',
        icon: true,
      },
      {
        points:
          "If you don't export, you must pay IGST in addition to interest.",
        icon: true,
      },
      {
        points: `(b): Give Information to Independent Witnesses: In the red boxes, list the names, jobs, and 
          addresses of two independent witnesses.`,
        icon: true,
      },
      {
        points: `It is noteworthy that the Witnesses listed in the Letter of Undertaking (LUT) are also listed 
        on the running Bond/Bank guarantee.`,
        icon: true,
      },
    ],
  };

  /*  Content Img Component Data */
  const lutCiData = {
    id: '',
    background: 'main-content',
    mt_div: 'mt-3',
    header: 'GST Form Filling',
    paragraph: ``,
    points: [
      {
        head: 'Step-5:',
        content: `Choose a location for the file. Before submitting the form, double-check its accuracy 
        by clicking "SAVE" "PREVIEW." Please be aware that at this time, forms that have been signed and 
        submitted cannot be changed.`,
        icon: true,
      },
      {
        head: 'Step-6:',
        content: `The principal authorised signature or any other authorised signatories may both sign the 
        Letter of Undertaking. Authorized signatures may include the Managing Director, the working partner, 
        the Firm Secretary, the proprietor, or a person duly designated by the active partner or the Board of 
        Directors of the company or proprietor to execute the form.`,
        icon: true,
      },
      {
        content: `DSC Submission: Sign the application using the registered digital signature certificate of the authorised signatory.`,
        icon: true,
      },
      {
        content: `Submit With EVC: Go to 'SIGN AND FILE WITH EVC' to access this option. To the registered mobile phone number and email address of the authorised signatory, the system will issue an OTP.`,
        icon: true,
      },
    ],
    image: '/imgs/business/document-pvt.png',
    alt_tag: 'Required Documents for MsMe Registration',
  };

  /* Slider */
  const imgSlider = [
    '/imgs/business/compreg.png',
    '/imgs/business/responsible.png',
    '/imgs/business/sliders/producer-sliders.png',
  ];

  /*  Slider Content Component Data */
  const lutSCData = {
    id: 'lutSlide',
    background: [],
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: 'LUT Application Form',
    content: [
      {
        points: `Registered Name`,
        icon: true,
      },
      {
        points: `GSTIN Number`,
        icon: true,
      },
      {
        points: `Address`,
        icon: true,
      },
      {
        points: `Date of filling`,
        icon: true,
      },
      {
        points: `Date, Place and Signature`,
        icon: true,
      },
      {
        points: `Witness information (Name, address and occupation)`,
        icon: true,
      },
    ],
    images: imgSlider,
  };

  /* FaQ data */
  var GSTLUTFAQ = [
    {
      header: 'What is the GST RFD 11 form?',
      body: [
        {
          content: `Supplying a bond or a letter of commitment to make exporting goods
            or services easier.`,
          icon: false,
        },
      ],
    },
    // 2
    {
      header: 'Is it necessary to register a LUT for GST?',
      body: [
        {
          content: `In accordance with Central Tax Notification No. 37/2017, LUT is
            required in order to export goods, services, or both without
            having to pay IGST. The exporter is required to pay the IGST or
            post an export bond if the LUT is not provided. LUTs could
            previously only be submitted in person at the relevant GST office.`,
          icon: false,
        },
      ],
    },
    {
      header: 'What are the conditions for applying LUT?',
      body: [
        {
          content: `Any registered taxpayer exporting goods or services can make use of LUTs. However, 
          any person who has been prosecuted for tax evasion for an amount of Rs. 2.5 Crores or above under
           the act is not eligible to furnish LUTs.`,
          icon: false,
        },
      ],
    },
    {
      header: `Is it possible to obtain a LUT certificate through the GST portal?`,
      body: [
        {
          content: `In order to access the GST Portal, use correct credentials. 3. Click
            on the Services User Services menu and choose the Furnish Letter of
            Undertaking (LUT) action.`,
          icon: false,
        },
      ],
    },
    {
      header: `What happens if LUT is not filed on time?`,
      body: [
        {
          content: ` If LUT is not filed means the department is bound by the circular to condone delay and
           sanction refund on account of goods and services exported.`,
          icon: false,
        },
      ],
    },
    // 3
    {
      header: `Who is required to apply for a LUT under the GST?`,
      body: [
        {
          content: `All registered taxpayers must submit a LUT in Form GST RFD-11 on the
            GST Portal before undertaking a zero-rated supply of goods or
            services. Use legitimate login information to access the GST Portal.`,
          icon: false,
        },
      ],
    },
    // 4
    {
      header: `Is LUT required for export?`,
      body: [
        {
          content: `Without a LUT, exporting is not feasible. The laws provide that an
            exporter must give a bond or Letter of Undertaking (LUT) to the
            relevant Commissioner before making zero-rated shipments.`,
          icon: false,
        },
      ],
    },
    {
      header: `What is a validity of LUT under GST?`,
      body: [
        {
          content: `Every financial year, the qualifying taxpayer must provide a LUT under GST. 
          This means that a LUT issued under GST is only valid for one financial year. If the taxpayer fails 
          to follow the terms and circumstances of the Letter of Undertaking, he or she must provide a bond 
          instead of the LUT (LUT).`,
          icon: false,
        },
      ],
    },
    {
      header: `What happens if the exporter fails to export the LUT after it has been submitted?`,
      body: [
        {
          content: `From the date of issuing of the export invoice to the date of IGST payment, the exporter must 
          pay the applicable IGST plus interest at the rate of 18 percent per annum.`,
          icon: false,
        },
      ],
    },
    {
      header: ` Is there a requirement for exporters to file a bond?`,
      body: [
        {
          content: `No, the obligation to post a bond is no longer in effect. A Letter of Undertaking must be
           filed online for exports that do not include the payment of taxes.`,
          icon: false,
        },
      ],
    },
  ];
  return (
    <div>
      <Seo
        title='Gst Lut Filling'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />

      <Layout>
        <main id='main'>
          {/* <Hero sliders={lutSlider} /> */}

          <Package packageData={PackageCompData} />
          <AboutWithForm about_content={lutAboutData} />
          <Counter />
          <ImgContent item={lutIcData} />
          <ContentForm CFSection_data={lutCfData} />
          <SliderContent ScSection_data={lutSCData} />
          <ContentImg CISection_data={lutCiData} />
          <Cta />

          <Guidance />

          <FAQAccord items={GSTLUTFAQ} />

          <Testimonial />
        </main>
      </Layout>
    </div>
  );
}
