import React from 'react';
// import PriceDetails from './PriceDetails';


export default function AboutWithForm({ about_content }) {
    return (
      <section section id='banner' className='banner style6 mt-5 mb-5'>
        <div className='container mt-5'>
          <div className='row mt-5'>
            <div className='col-lg-3 d-flex align-items-center'>
              <header className='section-header d-flex align-items-center'>
                <h2>{about_content.header}</h2>
              </header>
            </div>
            <div className='col-lg-5 pr-10 md-mb-40 '>
              {about_content.content_paragraph.map((content, index) => (
                <p className='desc' key={index}>
                  {content}
                </p>
              ))}
            </div>

            <div className='col-lg-3 d-flex align-items-center '>
              <div className='sec-title mb-26'>
                {/* <header className="section-header">
                                <h2>{about_content.header}</h2>
                            </header> */}

                <div className='sub-title mt-2 text-center text-capitalize d-flex align-items-center '>
                  {about_content.sub_title}
                </div>
              </div>
            </div>
          </div>
          <div className='shape-animate'>
            <div className='transparent left mt-3'>
              <img src='/imgs/ban1.png' alt='images' />
            </div>
            <div className='transparent right mt-3'>
              <img src='/imgs/ban2.png' alt='images' />
            </div>
            <div
              className='transparent right mt-3 blink'
              style={{
                right: '90px',
              }}
            >
              {/* <img src="/imgs/1.png" alt="images" /> */}
              <h5 className='text-primary'>
                <a href='tel:+919585055661'>
                  Call for Consultation - +91 95850 55661
                </a>
              </h5>
            </div>
          </div>
        </div>
      </section>
    );
}
