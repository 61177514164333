import React from "react";
// import { SideBar } from "./Form";

export default function ContentForm({ CFSection_data }) {
  return (
    <section id={CFSection_data.id} className="main-content mt-5 mb-0">
      <div className="container ">
        <div className="row mt-5 ">
          <div className="col-lg-1 col-md-1"></div>
          <div className="col-lg-10 col-md-8 col-sm-12 content ">
            <div className="desc ipr">
              <div>
                <header className="section-header">
                  <h2>{CFSection_data.header}</h2>
                  <div className="bar"></div>
                </header>
                <p>{CFSection_data.content}</p>

                <div className="row">
                  <div className="col-md-12 mt-2">
                    {CFSection_data.body.map((each_point, index) => (
                      <div className="desc mt-4" key={index}>
                        <h5>{each_point.head}</h5>
                        <div className="row content-form">
                          <div className="col-md-1">
                            {each_point.icon ? (
                              <i className="bi bi-check-circle-fill"></i>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-md-11">{each_point.points}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ====================side bar form fill==================== */}
          {/* <SideBar /> */}
          <div className="col-lg-1 col-md-1"></div>
        </div>
      </div>
    </section>
  );
}
