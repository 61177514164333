import React from "react";
export default function Guidance() {
  return (
    <section className=' guidance mt-5'>
      <div className='container'>
        <header className='section-header'>
          <h2>Looking for Professional Guidance?</h2>
          <div className='bar'></div>
        </header>
        {/* <!-- Feature Icons --> */}
        <div className='row guidance-icons'>
          <div className='row'>
            <div className='col-md-12 d-flex content'>
              <div className='row'>
                <div className='col-md-12 mt-5 icon-box text-end'>
                  <div id='parent' className='col-md-9'>
                    <h4>Google Reviews</h4>
                    <p>
                      99% of our valuable Customers are giving high rating in
                      Google.
                    </p>
                  </div>
                  <div id='child' className='col-md-3'>
                    <img
                      src='/imgs/icons/legal.png'
                      alt='TODAYFILINGS - Legal Services'
                    />
                  </div>
                </div>

                <div className='col-md-12 icon-box text-end'>
                  <div id='parent' className='col-md-9'>
                    <h4>Turn Around Time</h4>
                    <p>99% of services will be delivered within timeline</p>
                  </div>
                  <div id='child' className='col-md-3'>
                    <img
                      src='/imgs/icons/itr-filing.png'
                      alt='TODAYFILINGS - ITR Filing Assistance'
                    />
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='guide mt-5'>
                  <img src='/imgs/values-3.png' alt='' />
                </div>
              </div>
              <div className='row business'>
                <div className='col-md-12 icon-box mt-5 text-left'>
                  <div id='child' className='col-md-3'>
                    <img
                      src='/imgs/icons/startup.png'
                      alt='TODAYFILINGS - Launch and Run a Startup service'
                    />
                  </div>
                  <div id='parent' className='col-md-9'>
                    <h4>Reasonable</h4>
                    <p>
                      Service at an affordable price with excellent service
                      delivery
                    </p>
                  </div>
                </div>

                <div className='col-md-12 icon-box text-left'>
                  <div id='child' className='col-md-3'>
                    <img
                      src='/imgs/icons/registration.png'
                      alt='TODAYFILINGS - Government Registration Services '
                    />
                  </div>
                  <div id='parent' className='col-md-9'>
                    <h4>300+ Services</h4>
                    <p>Relax at home, we take care of Tax/Compliance</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className='shape5 aos-init aos-animate'
        data-aos='fade-in'
        data-aos-offset='600'
      >
        <img src='/imgs/animation/shape5.png' alt='TODAYFILINGS' />
      </div>
    </section>
  );
}
